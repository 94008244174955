<template>
  <div>
    <!-- Filters -->
    <MessageHistoryListFilters
      :reference-filter.sync="referenceFilter"
      :fromdate-filter.sync="fromdateFilter"
      :todate-filter.sync="todateFilter"
      :phone-filter.sync="phoneFilter"
      :username-filter.sync="usernameFilter"
      :balance-type="balanceType"
      :balance-type-from-filter.sync="balanceTypeFromFilter"
      :isUsername.sync="isUsername"
      :isPhone.sync="isPhone"
    />

    <b-card v-if="fetchCashLogs" no-body class="border">
      <div class="m-2">
        <b-row>
          <b-col cols="12">
            <div class="d-flex align-items-center justify-content-end">
              <b-button variant="primary" @click="showAddMessageAllModal">
                <span class="text-nowrap">
                  <feather-icon icon="PlusIcon" /> New
                </span>
              </b-button>
            </div>
          </b-col>
        </b-row>
      </div>
      <b-table
        ref="refCashLogsListTable"
        class="position-relative table-white-space mb-0 max-height-table"
        sticky-header
        head-variant="light"
        :no-border-collapse="true"
        :items="fetchCashLogs"
        responsive
        :fields="tableColumns"
        primary-key="index"
        :sort-by.sync="sortBy"
        show-empty
        empty-text="No matching records found"
        :sort-desc.sync="isSortDirDesc"
      >
        <template #cell(index)="data">
          {{ perPage * (currentPage - 1) + data.index + 1 }}
        </template>

        <template #cell(message_title_id)="data">
          {{ getTitleById(data.item.message_title_id) }}
        </template>

        <template #cell(username)="data">
          <div v-if="data.item.user">
            {{ data.item.user.username }}
          </div>
        </template>
        <template #cell(phone)="data">
          {{ data.item.phone }}
        </template>

        <template #cell(text_msg)="data">
          <div style="max-width: 500px; white-space: pre-wrap">
            {{ data.item.text_msg }}
          </div>
        </template>

        <template #cell(createdAt)="data">
          {{ formatDateTime(data.item.created_at).replace(".000000Z", "") }}
        </template>
        <template #cell(updated_at)="data">
          {{
            formatDateTime(data.item.updated_at).replace(".000000Z", "")
          }} </template
        ><template #cell(created_by)="data">
          {{ data.item.created_by }}
        </template>
      </b-table>
      <div class="p-2">
        <b-row>
          <b-col
            cols="12"
            sm="6"
            class="
              d-flex
              align-items-center
              justify-content-center justify-content-sm-start
            "
          >
            <span class="text-muted"
              >Showing {{ dataMeta.from }} to {{ dataMeta.to }} of
              {{ dataMeta.of }} entries</span
            >
          </b-col>
          <!-- Pagination -->
          <b-col
            cols="12"
            sm="6"
            class="
              d-flex
              align-items-center
              justify-content-center justify-content-sm-end
            "
          >
            <b-pagination
              v-model="currentPage"
              :total-rows="totalCashLogs"
              :per-page="perPage"
              first-number
              last-number
              class="mb-0 mt-1 mt-sm-0"
              prev-class="prev-item"
              next-class="next-item"
            >
              <template #prev-text>
                <feather-icon icon="ChevronLeftIcon" size="18" />
              </template>
              <template #next-text>
                <feather-icon icon="ChevronRightIcon" size="18" />
              </template>
            </b-pagination>
          </b-col>
        </b-row>
      </div>
    </b-card>
    <user-message-all-modal @refetch-data="refetchData" />
  </div>
</template>

<script>
import {
  BButton,
  BModal,
  VBModal,
  BCard,
  BRow,
  BCol,
  BTable,
  BTr,
  BTh,
  BLink,
  BBadge,
  BPagination,
} from "bootstrap-vue";
import Ripple from "vue-ripple-directive";
import vSelect from "vue-select";
import { ref, watch, computed, onUnmounted } from "@vue/composition-api";
import { useToast } from "vue-toastification/composition";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import { numberFormat, formatDateTime } from "@core/utils/filter";
import store from "@/store";
import router from "@/router";
import cashLogStoreModule from "./cash-log/cashLogStoreModule";
import MessageHistoryListFilters from "./cash-log/MessageHistoryListFilters.vue";
import i18n from "@/libs/i18n";
import UserMessageAllModal from "@/views/apps/user/users-detail/UserMessageAllModal.vue";
import userStoreModule from "../userStoreModule";
export default {
  components: {
    MessageHistoryListFilters,
    UserMessageAllModal,
    BButton,
    BModal,
    BCard,
    BRow,
    BCol,
    BTable,
    BTr,
    BTh,
    BLink,
    BBadge,
    BPagination,
    vSelect,
  },
  directives: {
    "b-modal": VBModal,
    Ripple,
  },
  props: {},
  methods: {
    showAddMessageAllModal() {
      this.$bvModal.show("modal-detail-message-user-all");
    },
  },
  setup() {
    const perPage = ref(25);
    const totalCashLogs = ref("");
    const currentPage = ref(1);
    const userData = ref({});
    const perPageOptions = [10, 25, 50, 100];
    const sortBy = ref("id");
    const isSortDirDesc = ref(true);
    const isPhoneAdd = ref(true);
    const isUsername = ref(true);
    const isPhone = ref(true);

    const referenceFilter = ref(null);
    const fromdateFilter = ref(null);
    const todateFilter = ref(null);
    const phoneFilter = ref(null);
    const usernameFilter = ref(null);
    const balanceType = ref([]);
    const balanceTypeFromFilter = ref(null);

    const refCashLogsListTable = ref(null);
    const toast = useToast();

    const CASH_LOG_STORE_MODULE_NAME = "app-user-cash-log";

    // Register module
    if (!store.hasModule(CASH_LOG_STORE_MODULE_NAME))
      store.registerModule(CASH_LOG_STORE_MODULE_NAME, cashLogStoreModule);
    if (!store.hasModule("app-user"))
      store.registerModule("app-user", userStoreModule);

    // UnRegister on leave
    onUnmounted(() => {
      // if (store.hasModule(CASH_LOG_STORE_MODULE_NAME)) store.unregisterModule(CASH_LOG_STORE_MODULE_NAME)
    });

    const tableColumns = [
      { key: "index", label: "#" },
      { key: "message_title_id", label: "Tiêu đề" },
      { key: "username", label: "Tên người dùng" },
      { key: "phone", label: "Điện thoại" },
      { key: "text_msg", label: "Nội dung tin nhắn" },
      { key: "created_by", label: "Người tạo" },
      { key: "updated_at", label: "Update at" },
      { key: "createdAt", label: "Created at" },
    ];

    const refetchData = () => {
      refCashLogsListTable.value.refresh();
    };

    watch(
      [
        currentPage,
        // perPage,
        referenceFilter,
        fromdateFilter,
        todateFilter,
        usernameFilter,
        phoneFilter,
        balanceTypeFromFilter,
      ],
      () => {
        refetchData();
      }
    );
    store.dispatch("app-user-cash-log/fetchTitleMessage").then((response) => {
      balanceType.value = response.data.data.map((val) => {
        return {
          name: val.title,
          value: val.id,
        };
      });
    });

    const fetchCashLogs = (ctx, callback) => {
      store
        .dispatch("app-user-cash-log/fetchMessageHistory", {
          perPage: perPage.value,
          page: currentPage.value,
          sortBy: sortBy.value,
          sortDesc: isSortDirDesc.value,
          // userId: router.currentRoute.params.id,
          messageTitleId: referenceFilter.value,
          fromdate: fromdateFilter.value,
          message_title_id: balanceTypeFromFilter.value,
          todate: todateFilter.value,
          phone: phoneFilter.value,
          username: usernameFilter.value,
        })
        .then((response) => {
          const { cashlogs, total, pageSize } = response;
          callback(cashlogs);
          totalCashLogs.value = total;
          perPage.value = pageSize;
        })
        .catch(() => {
          toast({
            component: ToastificationContent,
            props: {
              title: "Error fetching cash logs list",
              icon: "AlertTriangleIcon",
              variant: "danger",
            },
          });
        });
    };
    const getTitleById = (id) => {
      const filteredItems = balanceType.value.filter(
        (item) => item.value === id
      );
      return filteredItems.length > 0 ? filteredItems[0].name : "";
    };

    const dataMeta = computed(() => {
      const localItemsCount = refCashLogsListTable.value
        ? refCashLogsListTable.value.localItems.length
        : 0;
      return {
        from:
          perPage.value * (currentPage.value - 1) + (localItemsCount ? 1 : 0),
        to: perPage.value * (currentPage.value - 1) + localItemsCount,
        of: totalCashLogs.value,
      };
    });

    return {
      fetchCashLogs,
      refetchData,
      tableColumns,
      perPage,
      currentPage,
      totalCashLogs,
      dataMeta,
      perPageOptions,
      sortBy,
      isSortDirDesc,
      refCashLogsListTable,

      numberFormat,
      formatDateTime,

      referenceFilter,
      fromdateFilter,
      todateFilter,
      balanceType,
      balanceTypeFromFilter,
      getTitleById,
      isPhoneAdd,
      userData,
      phoneFilter,
      usernameFilter,
      isPhone,
      isUsername,
    };
  },
};
</script>
