<template>
  <div>
    <b-modal
      id="modal-detail-message-user-all"
      cancel-variant="outline-secondary"
      :ok-title="$t('Send message')"
      :cancel-title="$t('Close')"
      size="lg"
      centered
      :title="$t('Add message')"
      @ok="confirmUpdateStatus"
    >
      <!-- form -->
      <validation-observer ref="changeUserStatus">
        <b-form
          method="POST"
          class="auth-reset-password-form mt-2"
          @submit.prevent="validationFormStatus"
        >
          <!-- Field: Template -->
          <b-form-group :label="$t('Template')" label-for="user-template-sms">
            <v-select
                v-model="template"
                v-on:input="changeTemplate()"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                :options="templateSmsOption"
                :reduce="(val) => val.value"
                :clearable="false"
                input-id="user-template-sms"
            />
          </b-form-group>
          <!-- Field: Status -->
          <b-form-group :label="$t('Tiêu đề')" label-for="user-status">
            <v-select
              v-model="stat"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="titleMessage"
              :reduce="(val) => val.value"
              :clearable="false"
              input-id="user-status"
            />
          </b-form-group>

          <!-- Field: Status -->
          <b-form-group
            :label="$t('Nội dung tin nhắn')"
            label-for="user-status-note"
          >
            <validation-provider #default="{ errors }" name="Message">
              <!-- rules="required" -->
              <b-form-textarea
                id="user-status-note"
                v-model="note"
                rows="5"
                max-rows="10"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                :class="errors.length > 0 ? 'is-invalid' : null"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>

          <b-form-group :label="$t('Phone')" label-for="phone">
            <validation-provider #default="{ errors }" name="phone">
              <!-- rules="required|numeric" -->
              <b-form-input
                type="number"
                id="phone"
                v-model="phone"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                :class="errors.length > 0 ? 'is-invalid' : null"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
        </b-form>
      </validation-observer>
    </b-modal>
  </div>
</template>

<script>
import { ValidationProvider, ValidationObserver } from "vee-validate";
import {
  BLink,
  BRow,
  BCol,
  BForm,
  BFormGroup,
  BFormInput,
  BButton,
  BInputGroup,
  BInputGroupPrepend,
  BModal,
  VBModal,
  BInputGroupAppend,
  BFormTextarea,
} from "bootstrap-vue";
import { ref, onUnmounted } from "@vue/composition-api";
import vSelect from "vue-select";
import Ripple from "vue-ripple-directive";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import store from "@/store";
import cashLogStoreModule from "@/views/apps/user/users-detail/cash-log/cashLogStoreModule";
import templateSmsStoreModule from "@/views/reason/template-sms/templateSmsStoreModule";

export default {
  components: {
    BLink,
    BRow,
    BCol,
    BForm,
    BFormGroup,
    BFormInput,
    BButton,
    BInputGroup,
    BInputGroupPrepend,
    BModal,
    BFormTextarea,
    BInputGroupAppend,
    ValidationProvider,
    ValidationObserver,
    vSelect,
  },
  directives: {
    "b-modal": VBModal,
    Ripple,
  },
  props: {
    //   userData: {
    //     type: Object,
    //     required: true,
    //   },
    // titleMessage: {
    //   type: Array,
    //   required: [],
    // },
    //   isPhoneAdd: {
    //     type: Boolean,
    //     required: true,
    //   },
  },
  data() {
    return {
      template: "",
      stat: "",
      note: "",
      phone: "",
    };
  },
  watch: {},
  methods: {
    validationFormStatus() {
      this.$refs.changeUserStatus.validate().then((success) => {
        if (success) {
          const uData = {
            //   userId:  "",
            messageTitleId: this.stat,
            textMsg: this.note,
            phone: this.phone,
          };
          store
            .dispatch("app-user/postMessageAllUser", uData)
            .then((response) => {
              if (response.data.code === "00") {
                this.$toast({
                  component: ToastificationContent,
                  props: {
                    title: "Success",
                    icon: "EditIcon",
                    variant: "success",
                  },
                });
                this.$emit("refetch-data");
                this.$nextTick(() => {
                  this.$bvModal.hide("modal-detail-message-user-all");
                  this.resetModal();
                });
                // this.$emit("update:statusUpdateInfo", this.stat);
                // this.$emit("update:statusUpdate", !this.statusUpdate);
              } else {
                // console.log(12312, response);
                // const { response } = error;
                this.$toast({
                  component: ToastificationContent,
                  props: {
                    title: response.data.message || "Error",
                    icon: "AlertTriangleIcon",
                    variant: "danger",
                  },
                });
              }
            })
            .catch((error) => {
              const { response } = error;
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: response.data.message || "Error Update User Status",
                  icon: "AlertTriangleIcon",
                  variant: "danger",
                },
              });
            });
        }
      });
    },
    resetModal() {
      this.status = "";
      this.note = "";
      this.phone = "";
      this.template = "";
    },
    confirmUpdateStatus(bvModalEvt) {
      bvModalEvt.preventDefault();
      this.validationFormStatus();
    },
    changeTemplate() {
      this.note = this.template;
    }
  },
  setup() {
    const CASH_LOG_STORE_MODULE_NAME = "app-user-cash-log";
    // const userLevelName = ref(null);
    const titleMessage = ref([]);
    const templateSmsOption = ref([]);

    // Register module
    if (!store.hasModule(CASH_LOG_STORE_MODULE_NAME))
      store.registerModule(CASH_LOG_STORE_MODULE_NAME, cashLogStoreModule);
    if (!store.hasModule("template-sms"))
      store.registerModule('template-sms', templateSmsStoreModule);

    // UnRegister on leave
    onUnmounted(() => {
      // if (store.hasModule(CASH_LOG_STORE_MODULE_NAME)) store.unregisterModule(CASH_LOG_STORE_MODULE_NAME)
    });

    store.dispatch("app-user-cash-log/fetchTitleMessage").then((response) => {
      titleMessage.value = response.data.data.map((val) => {
        return {
          label: val.title,
          value: val.id,
        };
      });
    });

    store.dispatch("template-sms/fetchTemplateSmsList", {
        page: null,
        id: null,
      })
        .then((response) => {
      templateSmsOption.value = response.data.data.map((val) => {
        return {
          label: val.content.slice(0, 100),
          value: val.content,
        };
      });
    });
    return { titleMessage, templateSmsOption };
  },
};
</script>

<style></style>
